import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import logger from 'loglevel';

@Injectable({
    providedIn: 'root',
})
export class ImageService {
    constructor() {}

    /**
     * Capture a photo using the device camera.
     * @returns A Promise that resolves to the base64 string of the captured image or null.
     */
    async takePhoto(): Promise<string | null> {
        try {
            const photo: Photo = await Camera.getPhoto({
                quality: 50,
                resultType: CameraResultType.Base64,
                source: CameraSource.Camera,
                correctOrientation: true,
                width: 800,
            });
            return `data:image/jpeg;base64,${photo.base64String}`;
        } catch (error) {
            logger.error('Error capturing photo:', error);
            return null;
        }
    }

    /**
     * Select an image from the device's photo library.
     * @returns A Promise that resolves to the base64 string of the selected image or null.
     */
    async getImageFromGallery(): Promise<string | null> {
        try {
            const photo: Photo = await Camera.getPhoto({
                quality: 50,
                resultType: CameraResultType.Base64,
                source: CameraSource.Photos,
                correctOrientation: true,
                width: 800,
            });
            return `data:image/jpeg;base64,${photo.base64String}`;
        } catch (error) {
            logger.error('Error selecting image from gallery:', error);
            return null;
        }
    }
}
