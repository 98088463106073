import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { Injectable } from '@angular/core';
import { EMAIL_REGEX } from '../../const/app.const';

@Injectable({
    providedIn: 'root',
})
export class ValidationService {
    private phoneUtil = PhoneNumberUtil.getInstance();
    constructor() {}

    isValidPhoneNumber(phone) {
        try {
            const pn = this.phoneUtil.parse(phone);
            return this.phoneUtil.isPossibleNumber(pn);
        } catch (err) {
            return false;
        }
    }

    isValidEmail(email) {
        return EMAIL_REGEX.test(email);
    }

    validatePhoneNumber(phone: string) {
        try {
            const pn = this.phoneUtil.parse(phone);
            if (this.phoneUtil.isPossibleNumber(pn)) {
                return this.phoneUtil.format(pn, PhoneNumberFormat.INTERNATIONAL);
            } else {
                return null;
            }
        } catch (err) {
            return null;
        }
    }
}
