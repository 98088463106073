import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { OPTIONS, STEP_TYPE } from '../../../const/ticket.const';
import { IonContent, Platform } from '@ionic/angular';
import { Timeout } from '../../../util/timeout';
import { ImageService } from '../../../services/image/image.service';
import { TicketNode } from '../../../models/ticket-node';
import { DocumentUtilService } from '../../../services/document-util/document-util.service';
import { UserService } from 'src/app/services/user/user.service';
import { InputOptions, InputType } from '../../../const/input.const';
import { Capacitor } from '@capacitor/core';

@Component({
    selector: 'app-chat-view',
    templateUrl: './chat-view.component.html',
    styleUrls: ['./chat-view.component.scss'],
})
export class ChatViewComponent implements OnChanges {
    contentMinHeight = 0;
    disableSubmit = false;
    isNativeApp = false;
    userInput = '';
    STEP_TYPE = STEP_TYPE;
    InputType = InputType;
    @Input('messages') messages;
    @Input('inputOptions') inputOptions: InputOptions;
    @ViewChild(IonContent, { static: true }) content;
    @ViewChild('scrollContent', { static: true }) scrollContent;
    @ViewChild('documentInput') documentInput;
    @ViewChild('description') description;
    @ViewChild('phone') phone;
    mobileRegex = /[^\+0-9 \(\)\/]*/g;
    @Output() optionSelected: EventEmitter<TicketNode> = new EventEmitter<TicketNode>();
    @Output() textEntered: EventEmitter<string> = new EventEmitter<string>();
    @Output() imagesUploaded: EventEmitter<string[] | Document[]> = new EventEmitter<string[] | Document[]>();
    @Output() imageDeleted: EventEmitter<number> = new EventEmitter<number>();

    constructor(
        private imageService: ImageService,
        public platform: Platform,
        private documentUtil: DocumentUtilService,
        private userService: UserService
    ) {
        this.isNativeApp = Capacitor.isNativePlatform();
        // This fixes a bug on both android and ios where the footer wasn't being scrolled into view when the keyboard was triggered
        const ionApp = document.getElementsByTagName('ion-app')[0];
        window.addEventListener('keyboardDidShow', () => {
            const activeElement: any = document.activeElement;
            if (activeElement.type === 'textarea') {
                activeElement.scrollIntoView();
            }
            if (platform.is('android')) {
                ionApp.style['max-height'] = '100%';
            }
        });
        window.addEventListener('keyboardDidHide', () => {
            ionApp.style['margin-bottom'] = 0;
            if (platform.is('android')) {
                ionApp.style['max-height'] = 'initial';
            }
        });
    }

    async ngOnChanges(changes: SimpleChanges) {
        await Timeout(50);

        const curVal = changes.messages?.currentValue || [];
        if (changes.inputOptions && !changes.messages?.firstChange) {
            this.disableSubmit = false;
            if (
                !this.userService?.user?.mobile &&
                curVal.length &&
                curVal[curVal.length - 1].options[0] &&
                curVal[curVal.length - 1].options[0].id === OPTIONS.PHONE_CORRECT
            ) {
                curVal[curVal.length - 1].options[0].hidden = true;
            }
            await Timeout(50);
            if (this.description || this.phone) {
                this.description ? this.description.setFocus() : this.phone.setFocus();
            }
        }
        this.content.scrollToBottom(300);
        await Timeout(300);
        this.contentMinHeight = this.scrollContent.nativeElement.offsetHeight;
    }

    async submitText() {
        this.textEntered.emit(this.userInput);
        this.disableSubmit = true;
        this.userInput = '';
    }

    async addImageFromGallery() {
        if (this.isNativeApp) {
            const image = await this.imageService.getImageFromGallery();
            this.imagesUploaded.emit([image]);
        } else {
            this.documentInput.nativeElement.click();
        }
    }

    async takePhoto() {
        const photo = await this.imageService.takePhoto();
        if (photo) {
            this.imagesUploaded.emit([photo]);
        }
    }

    async optionClicked(option: TicketNode) {
        if (this.userInput && option.id !== 'phone_correct') {
            this.submitText();
        }
        this.optionSelected.emit(option);
        this.userInput = '';
    }

    isMessageHidden(message) {
        if (Array.isArray(message.options) && message.options.length) {
            for (let i = 0; i < message.options.length; i++) {
                if (!message.options[i].hidden) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    onImageSelected($event) {
        if ($event.target.files) {
            const images = $event.target.files;
            const promises = [];
            if (images) {
                const docLength = images.length;
                for (let index = 0; index < docLength; index++) {
                    promises.push(this.documentUtil.readFile(images[index], images[index].name, images[index].type));
                }
            }
            Promise.all(promises)
                .then((loadedImages: Array<any>) => {
                    for (const img in loadedImages) {
                        loadedImages[img].original = images[img];
                    }
                    this.imagesUploaded.emit(loadedImages);
                    this.documentInput.nativeElement.value = null;
                })
                .catch(() => (this.documentInput.nativeElement.value = null));
        }
    }

    deleteImage(index: number) {
        this.imageDeleted.emit(index);
    }

    selectedItem(item) {
        this.userInput = item;
    }
    setAddressOptions(input: any) {
        this.inputOptions.type = input;
    }

    isNoAdress(): boolean {
        return this.inputOptions.type !== InputType.GooglePlaces && this.inputOptions.type !== InputType.Address;
    }
}
