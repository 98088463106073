<ion-card (click)="onSelectCheckpoint(checkpointId)" class="accent-border">
    <ion-row class="ion-padding no-padding-top">
        <ion-col size="2">
            <span class="checkpoint-number">{{ numbering ? numbering : index + 1 }}</span>
        </ion-col>
        <ion-col>
            <ion-row>
                <ion-text class="text-bold" style="width: 100%">
                    <app-ticket-text
                        [title]="null"
                        [ticketId]="null"
                        [key]="'checkpoint.' + checkpointId + '.title'"></app-ticket-text>
                </ion-text>
            </ion-row>
            <ion-row *ngIf="type">
                <ion-text>{{ 'evote.checkpoint.type.' + type | translate }}</ion-text>
            </ion-row>
            <ion-row *ngIf="closedCheckpoint && assemblyClosed">
                <ion-text color="danger" *ngIf="!closedCheckpoint.accepted && relevant">
                    <b>{{ 'evote.checkpoint.type.closed.declined' | translate }}</b>
                </ion-text>
                <ion-text color="success" *ngIf="closedCheckpoint.accepted && relevant">
                    <b>{{ 'evote.checkpoint.type.closed.accepted' | translate }}</b>
                </ion-text>
            </ion-row>
        </ion-col>
    </ion-row>

    <ion-row class="ion-margin-start ion-margin-end voted-label-container">
        <ng-container *ngIf="votable && relevant">
            <ion-button class="voted-label" size="small" color="success" *ngIf="accepted === true">
                {{ 'evote.checkpoint.yes' | translate }}
            </ion-button>
            <ion-button class="voted-label" size="small" color="danger" *ngIf="accepted === false">
                {{ 'evote.checkpoint.no' | translate }}
            </ion-button>
            <ion-button class="voted-label" size="small" *ngIf="voted && accepted === null && delegated == null">
                {{ 'evote.checkpoint.abstained' | translate }}
            </ion-button>
        </ng-container>
        <ion-button *ngIf="!relevant" color="warning" class="voted-label" size="small">
            {{ 'evote.checkpoint.irrelevant' | translate }}
        </ion-button>

        <ion-icon
            *ngIf="
                (closedCheckpoint || assemblyClosed) &&
                type !== CHECKLISTTYPES.NODECISION &&
                type !== CHECKLISTTYPES.TBD &&
                relevant
            "
            color="danger"
            name="lock-closed-outline"></ion-icon>
    </ion-row>

    <ion-row class="align-chip ion-margin">
        <ion-chip color="danger" class="not-voted-button" *ngIf="!voted && relevantCheckpointType && relevant">
            {{ 'evote.checkpoint.notvoted' | translate }}
        </ion-chip>

        <ion-chip color="medium" *ngIf="latestTimestamp && latestVoterName" class="last-vote-item">
            {{ latestTimestamp | date: 'dd.MM.yyyy | HH:mm' }} {{ 'evote.checkpoint.vote-by' | translate }}
            {{ latestVoterName }}
        </ion-chip>
        <ion-icon *ngIf="hasAttachments" color="primary" name="attach" class="align-attachment-icon"></ion-icon>
    </ion-row>
</ion-card>
