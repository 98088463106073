import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from '../services/user/user.service';
@Injectable({
    providedIn: 'root',
})
export class ModuleGuard {
    constructor(
        private userService: UserService,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        return this.checkModulePermission(route);
    }

    canActivateChild(route: ActivatedRouteSnapshot): boolean {
        return this.checkModulePermission(route);
    }

    checkModulePermission(route: ActivatedRouteSnapshot): boolean {
        let permitted = false;
        const fallbackURL = route.data?.fallbackURL || null;
        const availableModules = this.userService?.moduleSubscription?.value || [];

        permitted = this.getPermission(route, availableModules);

        if (!permitted && fallbackURL) {
            this.router.navigate([fallbackURL]);
        }

        return permitted;
    }

    getPermission(route: ActivatedRouteSnapshot, availableModules: string[]): boolean {
        return !!(
            !route.hasOwnProperty('data') ||
            !route.data?.hasOwnProperty('requiredModule') ||
            route.data.requiredModule.every((req) => availableModules.includes(req))
        );
    }
}
