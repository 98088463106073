<ion-header>
    <app-topbar
        [title]="'ticket-create.entity-selection.header' | translate"
        [left]="'close'"
        (leftClicked)="close()"></app-topbar>
</ion-header>
<ion-content>
    <ion-list>
        <ion-radio-group (ionChange)="entitySelected($event.detail.value)">
            <ng-container *ngFor="let flatWithProperty of flatsWithProperties; index as index">
                <ion-item lines="none" class="ion-margin-top">
                    <ion-label
                        *ngIf="flatWithProperty.property.formattedLabel"
                        [innerHTML]="flatWithProperty.property.formattedLabel"></ion-label>
                    <ion-label *ngIf="!flatWithProperty.property.formattedLabel">
                        <h5>
                            {{ 'general.flat-types.property' | translate }}
                        </h5>
                        {{ flatWithProperty.property.street }},
                        {{ flatWithProperty.property.city }}
                        {{ flatWithProperty.property.name ? ' (' + flatWithProperty.property.name + ')' : '' }}
                    </ion-label>
                    <ion-radio
                        *ngIf="!ns.ticketConfig?.disablePropertyTickets"
                        mode="md"
                        slot="end"
                        [value]="flatWithProperty.property.id"></ion-radio>
                </ion-item>
                <ion-item
                    *ngFor="let flat of flatWithProperty.flats; index as flatIndex"
                    [ngClass]="{
                        last: flatIndex === flatWithProperty.flats.length - 1,
                    }"
                    [lines]="flatIndex === flatWithProperty.flats.length - 1 ? 'full' : 'none'">
                    <ion-icon name="return-down-forward" slot="start"></ion-icon>
                    <ion-label *ngIf="flat.formattedLabel" [innerHTML]="flat.formattedLabel"></ion-label>
                    <ion-label *ngIf="!flat.formattedLabel">
                        <h5>
                            {{ 'general.flat-types.' + flat.type | translate }}
                        </h5>
                        {{ flat.name }}
                        {{ flat.floor ? ' | ' + flat.floor : '' }}
                    </ion-label>
                    <ion-radio mode="md" slot="end" [value]="flat.id"></ion-radio>
                </ion-item>
            </ng-container>
        </ion-radio-group>
    </ion-list>
</ion-content>
<ion-footer>
    <ion-toolbar class="entity-selection-footer">
        <ion-button
            class="button-with-radius entity-selection-button"
            [disabled]="!selectedEntity"
            expand="block"
            (click)="close(true)">
            {{ 'general.done' | translate }}
        </ion-button>
    </ion-toolbar>
</ion-footer>
