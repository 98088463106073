import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { FlatService } from '../flat/flat.service';
import {
    collection,
    collectionData,
    doc,
    docData,
    Firestore,
    limit,
    orderBy,
    query,
    where,
} from '@angular/fire/firestore';
import { NsUtil } from '../../util/ns';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import _ from 'lodash-es';
import { ElasticService } from '../elastic/elastic.service';
import { OwnershipAssembly } from '../../models/ownershipAssembly';
import { ChecklistService, CHECKLISTTYPES } from '../checklist/checklist.service';
import { Checkpoint } from '../../models/checkpoint';
import { Vote } from '../../models/vote';
import { convertFirestoreDate } from '../../util/util';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../api/api.service';
import { FirebaseWrapperService } from '../firebase-wrapper/firebase-wrapper.service';
import logger from 'loglevel';

@Injectable({
    providedIn: 'root',
})
export class OwnershipAssemblyService {
    CHECKLISTTYPES = CHECKLISTTYPES;

    constructor(
        private userService: UserService,
        private flatService: FlatService,
        private firestore: Firestore,
        private firebaseService: FirebaseWrapperService,
        private ns: NsUtil,
        private http: HttpClient,
        private elastic: ElasticService,
        private checklistService: ChecklistService,
        private alertController: AlertController,
        private translate: TranslateService,
        private api: ApiService
    ) {}

    public getAllOwnershipAssembliesObservable(): Observable<OwnershipAssembly[]> {
        const states = ['invited', 'started', 'closed', 'processed'];
        const user = this.userService.user;
        let propIds = [];
        if (!user.assemblyObservable) {
            user.assemblyObservable = from(this.flatService.getFlatsByIds(user.ownedFlats)).pipe(
                switchMap((flats) => {
                    const propertyIds = [...new Set(flats.map((flat) => flat.propertyId))];
                    propIds = propertyIds;
                    return combineLatest(
                        states.map((state) =>
                            combineLatest(
                                propertyIds.map((id) => {
                                    const collectionRef = collection(
                                        this.firestore,
                                        `ns/${this.ns.getNs()}/ownershipAssemblies`
                                    );
                                    const q = query(
                                        collectionRef,
                                        where('properties', 'array-contains', id),
                                        where('state', '==', state),
                                        orderBy('startDate', 'asc')
                                    );
                                    return collectionData(q);
                                })
                            )
                        )
                    );
                }),
                switchMap((lists) => of(_.flattenDeep(lists))),
                switchMap((rawAssemblies: OwnershipAssembly[]) => {
                    if (!rawAssemblies.length) {
                        return of([]);
                    }

                    const assemblies = rawAssemblies.reduce(
                        (array, assembly) => [
                            ...array.filter((tempAssembly) => tempAssembly.id !== assembly.id),
                            assembly,
                        ],
                        []
                    );

                    const releasedAssemblies = assemblies.filter(
                        (assembly) => assembly.checklist?.releasedRevision && !assembly.migratedToV2
                    );

                    convertFirestoreDate(releasedAssemblies);

                    return combineLatest(
                        releasedAssemblies.map((assembly: OwnershipAssembly) => {
                            let validCheckpoints = [];
                            return this.checklistService
                                .getChecklistObservable(assembly.id, assembly.checklist.releasedRevision)
                                .pipe(
                                    switchMap((checklist: any) => {
                                        return this.checklistService
                                            .getCheckpointsObservable(assembly.id, checklist.checkpoints)
                                            .pipe(
                                                map((checkpoints: Checkpoint[]) => {
                                                    assembly.checkpoints = checkpoints;
                                                    validCheckpoints = checkpoints.filter(
                                                        (checkpoint) =>
                                                            checkpoint.type !== CHECKLISTTYPES.NODECISION &&
                                                            checkpoint.type !== CHECKLISTTYPES.TBD
                                                    );
                                                    return validCheckpoints;
                                                }),
                                                switchMap((checkpoints: Checkpoint[]) =>
                                                    this.getVotesOfAssemblyCheckpointByCheckpointsAsObservable(
                                                        assembly.id,
                                                        checkpoints.map((checkpoint) => checkpoint.id),
                                                        this.userService.user.id,
                                                        true,
                                                        true
                                                    ).pipe(
                                                        map((votes: Vote[]) => {
                                                            const delegationVotes = [];
                                                            const effectiveVotes = [];
                                                            const latestVotes = [];

                                                            for (const checkpoint of assembly.checkpoints) {
                                                                const votesByCategory = this.handleVotes(
                                                                    [this.userService.user.id],
                                                                    checkpoint.id,
                                                                    votes
                                                                );
                                                                delegationVotes.push(
                                                                    ...votesByCategory.delegationVotes
                                                                );
                                                                effectiveVotes.push(...votesByCategory.effectiveVotes);
                                                                latestVotes.push(...votesByCategory.latestVotes);
                                                            }

                                                            assembly.delegatedTo = delegationVotes.find(
                                                                (vote) => vote.delegatedTo
                                                            )?.delegatedTo;

                                                            assembly.voted =
                                                                this.getAllowedCheckpoints(validCheckpoints, propIds)
                                                                    .length == latestVotes.length;

                                                            assembly.latestVotes = latestVotes;

                                                            return assembly;
                                                        })
                                                    )
                                                )
                                            );
                                    })
                                );
                        })
                    );
                })
            );
        }
        return user.assemblyObservable;
    }

    getAssemblyObservable(assemblyId: string) {
        const namespace = this.userService.getNamespace();
        const docRef = doc(this.firestore, `ns/${namespace}/ownershipAssemblies/${assemblyId}`);

        return docData(docRef).pipe(
            switchMap((ass: any) =>
                from(this.checklistService.getChecklistById(assemblyId, ass.checklist.releasedRevision)).pipe(
                    switchMap((checklist) =>
                        this.getValidVotesOfAssembly(ass).pipe(
                            switchMap((votes: Vote[]) => {
                                ass.delegatedTo = votes.find((vote) => vote.delegatedTo)?.delegatedTo;
                                return this.checklistService
                                    .getCheckpointsObservable(ass.id, checklist['checkpoints'])
                                    .pipe(
                                        map((checkpoints: Checkpoint[]) =>
                                            checkpoints.filter(
                                                (checkpoint) =>
                                                    checkpoint.type !== CHECKLISTTYPES.NODECISION &&
                                                    checkpoint.type !== CHECKLISTTYPES.TBD
                                            )
                                        ),
                                        tap((filteredCheckpoints: Checkpoint[]) => {
                                            ass.voted =
                                                votes.length ===
                                                this.getAllowedCheckpoints(filteredCheckpoints || [])?.length;
                                        }),
                                        map(() => ass)
                                    );
                            })
                        )
                    )
                )
            )
        );
    }

    async getRawAssembly(assemblyId: string) {
        return this.firebaseService.docData(`ns/${this.userService.getNamespace()}/ownershipAssemblies`, assemblyId);
    }

    async vote(
        ownershipAssemblyId: string,
        checkpointId: string,
        ownerId: string,
        accepted: boolean,
        value = null,
        delegateToId: string = null,
        delegateToModel: string = null,
        reset: boolean = null,
        customData: any = null,
        disableDelegations = false,
        representiveVote = false
    ) {
        return await this.http
            .post(`${environment.apiBase}ownershipAssemblies/${ownershipAssemblyId}/checkpoint/${checkpointId}/vote`, {
                ownershipAssemblyId,
                checkpointId,
                voteOfId: ownerId,
                voteOfModel: 'owner',
                accepted,
                value,
                delegateToId,
                delegateToModel,
                reset,
                delegateCustomData: customData || null,
                disableDelegations,
                representiveVote,
            })
            .toPromise();
    }

    async voteNewDelegation(
        assembly: OwnershipAssembly,
        delegateToId: string,
        delegateToModel: string,
        reset = null,
        delegateCustomData = null
    ) {
        return await this.api.post(`ownershipAssemblies/${assembly.id}/delegate`, {
            createVoteDtos: assembly.checkpoints.map((checkpoint: Checkpoint) => {
                return {
                    ownershipAssemblyId: assembly.id,
                    checkpointId: checkpoint.id,
                    voteOfId: this.userService.user.id,
                    voteOfModel: 'owner',
                    accepted: null,
                    value: null,
                    delegateToId: delegateToId,
                    delegateToModel: delegateToModel,
                    reset,
                    delegateCustomData: delegateCustomData || null,
                };
            }),
        });
    }

    async loadRepresentives(assemblyId: string) {
        const delegates = await this.api.get(
            `ownershipAssemblies/${assemblyId}/getDelegation/${this.userService.user.id}`
        );

        const ownerIds = Object.keys(delegates);
        const tenants = await this.userService.getTenants([...new Set(ownerIds)] as string[]);

        for (const id of ownerIds) {
            delegates[id].name = this.userService.getTenantFullName(tenants.find((tenant) => tenant.id === id));
        }

        return delegates;
    }

    getVotesOfAssemblyCheckpointByOwnersAsObservable(
        assemblyId,
        checkpointId,
        ownerIds,
        includeAll = false,
        includeForeignVotes = false
    ) {
        return combineLatest(
            ownerIds.map((ownerId) =>
                this.getVotesOfAssemblyCheckpointByOwnerAsObservable(
                    assemblyId,
                    checkpointId,
                    ownerId,
                    includeAll,
                    includeForeignVotes
                )
            )
        ).pipe(
            switchMap((votes) => {
                return of(_.flatten(votes));
            })
        );
    }

    getVotesOfAssemblyCheckpointByOwnerAsObservable(
        assemblyId: string,
        checkpointId: string,
        ownerId: string,
        includeAll = false,
        includeForeignVotes = false
    ) {
        const collectionRef = collection(
            this.firestore,
            `ns/${this.userService.getNamespace()}/ownershipAssemblies/${assemblyId}/votes`
        );

        let q = query(collectionRef, where('checkpointId', '==', checkpointId), where('voteOf.id', '==', ownerId));

        if (!includeForeignVotes) {
            q = query(q, where('voteBy.id', '==', ownerId));
        }

        q = query(q, orderBy('timestamp', 'desc'));

        if (!includeAll) {
            q = query(q, limit(1));
        }

        return collectionData(q).pipe(
            map((votes: any) => (!includeAll ? votes.filter((vote) => vote.reset !== true) : votes))
        );
    }

    getVotesOfAssemblyCheckpointByCheckpointsAsObservable(
        assemblyId,
        checkpointIds,
        ownerId,
        includeAll,
        includeForeignVotes
    ) {
        if (!checkpointIds.length) {
            return of([]);
        }

        return combineLatest(
            checkpointIds.map((checkpointId) =>
                this.getVotesOfAssemblyCheckpointByOwnerAsObservable(
                    assemblyId,
                    checkpointId,
                    ownerId,
                    includeAll,
                    includeForeignVotes
                )
            )
        ).pipe(
            switchMap((votes) => {
                return of(_.flatten(votes));
            })
        );
    }

    async getOwnerOfPropertyAssembly(id: any[], type: string, searchString = '') {
        const searchResult = await this.elastic.searchUser(
            searchString,
            30,
            0,
            {
                'ownedProperties.keyword': id,
                type: type,
            },
            {},
            [],
            { 'id.keyword': 'desc' }
        );

        const ids: any[] = [...new Set(searchResult.ids)];
        const observables = ids.map((id) => {
            const docRef = doc(this.firestore, `users`, id);
            return docData(docRef);
        });
        return !observables.length
            ? of([])
            : combineLatest(...observables).pipe(
                  map((values: any) => {
                      const saneValues = values.filter((val) => Boolean(val));
                      for (const id of searchResult.ids) {
                          if (!saneValues.some((val) => val.id === id)) {
                              logger.warn(`Warning: Found id "${id}" via search but not in DB!!!`);
                          }
                      }
                      return saneValues;
                  })
              );
    }

    getValidVotesOfAssembly(assembly: OwnershipAssembly): Observable<Vote[]> {
        return this.checklistService.getChecklistObservable(assembly.id, assembly.checklist.releasedRevision).pipe(
            switchMap((checklist: any) => {
                return this.checklistService.getCheckpointsObservable(assembly.id, checklist.checkpoints).pipe(
                    map((checkpoint: Checkpoint[]) =>
                        checkpoint.filter(
                            (checkpoint) =>
                                checkpoint.type !== CHECKLISTTYPES.NODECISION && checkpoint.type !== CHECKLISTTYPES.TBD
                        )
                    ),
                    map((checkpoints: Checkpoint[]) => checkpoints.map((checkpoint) => checkpoint.id)),
                    switchMap((checkpoints: any) => {
                        return this.getVotesOfAssemblyCheckpointByCheckpointsAsObservable(
                            assembly.id,
                            checkpoints,
                            this.userService.user.id,
                            false,
                            false
                        );
                    })
                );
            })
        );
    }

    getEvaluatedTotalVotesOfAssemblyCheckpointByOwnersAsObservable(assemblyId, checkpointId, ownerIds) {
        return this.getVotesOfAssemblyCheckpointByOwnersAsObservable(
            assemblyId,
            checkpointId,
            ownerIds,
            true,
            false
        ).pipe(
            switchMap(async (votes: any) => {
                return this.handleVotes(ownerIds, checkpointId, votes);
            })
        );
    }

    handleVotes(ownerIds: string[], checkpointId: string, votes: any[]) {
        const latestVotes = [];
        const delegationVotes = [];
        const checkpointVotes = votes.filter((vote) => vote.checkpointId === checkpointId);

        checkpointVotes.sort((a, b) => {
            let aDate;
            let bDate;

            try {
                aDate = a.timestamp.toDate();
            } catch (e) {
                aDate = new Date(a.timestamp);
            }

            try {
                bDate = b.timestamp.toDate();
            } catch (e) {
                bDate = new Date(b.timestamp);
            }

            return bDate - aDate;
        });

        for (const vote of checkpointVotes) {
            if (vote.reset === true) {
                break;
            } else if (vote.delegatedTo) {
                delegationVotes.push(vote);
                break;
            }
        }

        const effectiveVotes = votes.filter(
            (vote) => vote.checkpointId === checkpointId && vote.delegatedTo === null && vote.reset !== true
        );

        for (const ownerId of ownerIds) {
            const latestUserVotes = effectiveVotes.filter((vote) => vote.voteOf.id === ownerId);
            if (latestUserVotes.length) {
                latestVotes.push(latestUserVotes[0]);
            }
        }

        if (delegationVotes.length) {
            for (const ownerId of ownerIds) {
                const userVotes = delegationVotes.filter((vote) => vote?.voteOf.id === ownerId);
                const delegates = userVotes.filter((vote) => vote.delegatedTo);
                const delegatesToUser = delegates.filter((vote) => vote.delegatedTo.model === 'owner');
                for (const delegateToUser of delegatesToUser) {
                    delegateToUser.delegatedTo.tenant = delegateToUser.delegatedTo.id;
                }
            }
        }

        return {
            effectiveVotes,
            delegationVotes,
            latestVotes,
        };
    }

    async togglePresence(ownershipAssemblyId: string) {
        return await this.http
            .post(
                `${environment.apiBase}ownershipAssemblies/${ownershipAssemblyId}/presentOwner/${this.userService.user.id}`,
                {}
            )
            .toPromise();
    }

    async getNeverVotedModal() {
        return await this.alertController.create({
            header: this.translate.instant('evote.alert.participate.title'),
            message: this.translate.instant('evote.alert.participate.message'),
            buttons: [
                {
                    text: this.translate.instant('evote.alert.participate.cancel'),
                },
                {
                    text: this.translate.instant('evote.alert.participate.action'),
                    role: 'confirm',
                },
            ],
        });
    }

    async setUserDefaultVotes(assemblyId: string, checkpointIds: string[]) {
        await Promise.all(checkpointIds.map((id) => this.vote(assemblyId, id, this.userService.user.id, null, null)));
    }

    getAllowedCheckpoints(checkpoints: any, propertyIds = this.userService.user.ownedProperties) {
        return checkpoints.filter(
            (checkpoint) =>
                !checkpoint.votePrivilegedProperties?.length ||
                checkpoint.votePrivilegedProperties.some((privilegedPropertyId) =>
                    propertyIds.includes(privilegedPropertyId)
                )
        );
    }
}
