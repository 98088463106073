import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user/user.service';
import { EMAIL_REGEX } from '../../const/app.const';
import logger from 'loglevel';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
    public form: UntypedFormGroup;
    private mail = '';

    constructor(
        public modalController: ModalController,
        private fb: UntypedFormBuilder,
        private authProvider: AuthService,
        private translate: TranslateService,
        private userService: UserService,
        private loadingController: LoadingController,
        private alertController: AlertController
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            email: [this.mail, [Validators.required, Validators.pattern(EMAIL_REGEX)]],
        });

        if (this.userService.user) {
            this.email.setValue(this.userService.user.email);
        }
    }

    async updateUserPassword() {
        const loader = await this.loadingController.create();
        await loader.present();

        try {
            await this.authProvider.sendPasswordResetMail(this.email.value);

            const alert = await this.alertController.create({
                header: this.translate.instant('login.reset-mail.successful'),
                message: this.translate.instant('login.reset-mail-sent'),
                buttons: [this.translate.instant('login.ok')],
            });
            await alert.present();
            this.modalController.dismiss();
        } catch (err) {
            let text = 'login.reset-mail-error';
            logger.error('Change Password failed', err);

            switch (err.status) {
                case 404:
                    text = 'login.email-not-exists';
                    break;
            }

            const alert = await this.alertController.create({
                header: this.translate.instant('login.reset-mail.error'),
                message: this.translate.instant(text),
                buttons: [this.translate.instant('login.ok')],
            });

            await alert.present();
        } finally {
            await loader.dismiss();
        }
    }

    get email() {
        return this.form.get('email');
    }
}
