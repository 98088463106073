import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { UserService } from '../../services/user/user.service';

const validWoonigLanguage = [
    'hy',
    'pa',
    'no',
    'ms',
    'bg',
    'sw',
    'el',
    'ka',
    'hu',
    'ku',
    'ky',
    'lo',
    'lb',
    'si',
    'vi',
    'uz',
    'en',
    'sm',
    'es',
    'cs',
    'da',
    'ja',
    'mn',
    'my',
    'pl',
    'ta',
    'so',
    'xh',
    'sv',
    'ca',
    'pt',
    'nl',
    'de',
    'az',
    'fr',
    'sr',
    'am',
    'ur',
    'it',
    'id',
    'ga',
    'lt',
    'af',
    'ne',
    'tg',
    'ps',
    'ru',
    'bn',
    'bs',
    'km',
    'tr',
    'et',
    'fi',
    'is',
    'lv',
    'mk',
    'mg',
    'mi',
    'ko',
    'zu',
    'sq',
    'ar',
    'be',
    'hr',
    'sk',
    'hi',
    'fa',
    'he',
    'kk',
    'mt',
    'ro',
    'sl',
    'th',
    'uk',
    'sn',
    'cy',
    'eo',
    'eu',
    'fy',
    'gd',
    'gl',
    'gu',
    'ha',
    'haw',
    'ig',
    'kn',
    'ml',
    'mr',
    'sd',
    'su',
    'yi',
    'yo',
    'zh-TW',
];

@Pipe({
    name: 'localeDate',
    pure: false,
})
export class LocaleDatePipe implements PipeTransform {
    constructor(private userService: UserService) {}

    transform(value: any, format: string = 'longDate'): string {
        if (!value) {
            return '';
        }

        if (typeof value === 'object' && value.seconds && value.nanoseconds) {
            value = new Date(value.seconds * 1000);
        }

        const locale = validWoonigLanguage.includes(this.userService.userLanguage$.value)
            ? this.userService.userLanguage$.value
            : 'en';

        return formatDate(value, format, locale);
    }
}
