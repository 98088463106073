import { Injectable } from '@angular/core';
import { collection, doc, docData, Firestore, getDoc, getDocs, limit, query } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root',
})
export class FirebaseWrapperService {
    constructor(private firestore: Firestore) {}

    public async docSnap(collectionPath: string, docId: string) {
        const docRef = doc(this.firestore, collectionPath, docId);
        return await getDoc(docRef);
    }

    public async docData(collectionPath: string, docId: string) {
        return (await getDoc(this.docReference(collectionPath, docId))).data();
    }

    public docReference(collectionPath: string, docId: string) {
        const collectionRef = collection(this.firestore, collectionPath);
        return doc(collectionRef, docId);
    }

    public docDataObservable(collectionPath: string, docId: string) {
        return docData(this.docReference(collectionPath, docId));
    }

    public async getCollectionDocuments(collectionPath: string, lmt: number = 1): Promise<any> {
        const collectionRef = collection(this.firestore, collectionPath);
        const limitedQuery = query(collectionRef, limit(lmt));
        const querySnapshot = await getDocs(limitedQuery);
        return querySnapshot.docs.map((doc) => {
            return doc.data();
        });
    }
}
