import { Component, Input, OnChanges, ViewChildren } from '@angular/core';
import { OwnershipAssembly } from '../../../models/ownershipAssembly';
import { Router } from '@angular/router';
import { VoteCardComponent } from '../../organisms/vote-card/vote-card.component';

@Component({
    selector: 'app-vote2-view',
    templateUrl: './vote-view.component.html',
    styleUrls: ['./vote-view.component.scss'],
})
export class Vote2ViewComponent implements OnChanges {
    @ViewChildren('voteCards') voteCards: VoteCardComponent[] = [];

    @Input() ownerShipAssemblies: OwnershipAssembly[] = [];

    constructor(private router: Router) {}

    ngOnInit() {
        this.sortAssemblies();
    }

    async ngOnChanges() {
        await Promise.all(this.voteCards.map((voteCard) => voteCard.initialize()));
    }

    async showAssemblyDetails(assembly: OwnershipAssembly) {
        this.navigateToAssembly(assembly.id);
    }

    navigateToAssembly(id) {
        this.router.navigateByUrl(`main/evote2/detail/${id}`);
    }

    sortAssemblies() {
        const now = new Date();

        this.ownerShipAssemblies = this.ownerShipAssemblies.sort((a, b) => {
            const dateA = new Date(a.startDate).getTime();
            const dateB = new Date(b.startDate).getTime();

            const diffA = Math.abs(dateA - now.getTime());
            const diffB = Math.abs(dateB - now.getTime());

            return diffA - diffB;
        });
    }
}
