import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { NamespaceInfo } from '../../models/namespace-info';
import { BaseDataService } from '../../util/base-data-service';
import { ApiService } from '../api/api.service';
import { FirebaseWrapperService } from '../firebase-wrapper/firebase-wrapper.service';

@Injectable({
    providedIn: 'root',
})
export class NamespaceService implements BaseDataService {
    private namespace: NamespaceInfo;
    ticketConfig: any;
    flatTypeConfig: any;

    constructor(
        private userService: UserService,
        private firebaseService: FirebaseWrapperService,
        private api: ApiService
    ) {}

    getName() {
        return this.namespace?.name;
    }

    get() {
        return this.namespace;
    }

    async initialize() {
        [this.ticketConfig, this.flatTypeConfig] = await Promise.all([
            await this.getTicketConfiguration(),
            this.getFlatTypeConfiguration(),
        ]);

        if (this.namespace) {
            return this.namespace;
        }

        this.namespace = (await this.firebaseService.docData('ns', this.userService.getNamespace())) as NamespaceInfo;
        return this.namespace;
    }

    async getNsSplashLogo() {
        if (!this.userService.user) {
            await this.userService.getUser();
        }

        await this.initialize();
        if (this.userService.user?.locations?.length === 1) {
            return (
                (await this.getUserLocationProfilePic(this.userService.user.locations[0])) ||
                this.namespace.profilePicture ||
                'assets/imgs/default_splash.png'
            );
        } else {
            return this.namespace.profilePicture || 'assets/imgs/default_splash.png';
        }
    }

    async getTicketConfiguration(): Promise<any> {
        return await this.firebaseService.docData(`ns/${this.userService.getNamespace()}/configurations`, 'tickets');
    }

    async getTenantConfiguration(): Promise<any> {
        return await this.firebaseService.docData(`ns/${this.userService.getNamespace()}/configurations`, 'tenants');
    }

    async getFlatTypeConfiguration(): Promise<any> {
        return await this.firebaseService.docData(`ns/${this.userService.getNamespace()}/configurations`, 'flatTypes');
    }

    async getUserLocationProfilePic(locationId: string): Promise<string | null> {
        try {
            const location = await this.firebaseService.docData(
                `ns/${this.userService.getNamespace()}/locations`,
                locationId
            );
            return location?.logo || null;
        } catch {
            return null;
        }
    }

    async getAnnouncements(
        namespaceId: string | null
    ): Promise<{ active: boolean; title: string; body: string; endDate: Date }> {
        const announcements = await this.firebaseService.getCollectionDocuments(
            `ns/${namespaceId ?? this.userService.getNamespace()}/announcements`
        );

        const user = await this.userService.getUser();

        const announcementId = announcements[0]?.id;
        const translation = await this.firebaseService.docData(
            `ns/${namespaceId ?? this.userService.getNamespace()}/announcements/${announcementId}/translations`,
            user ? user.language : this.namespace.language
        );

        return {
            active: announcements[0]?.active,
            title: translation?.value,
            body: translation?.value2,
            endDate: announcements[0]?.endDate ? announcements[0]?.endDate.toDate() : null,
        };
    }

    async getAnnouncementsFromApi(
        namespaceId: string,
        lang: string
    ): Promise<{ active: boolean; title: string; body: string; endDate: Date }> {
        const announcement = await this.api.get(`announcements/${namespaceId}/${lang}`);

        if (announcement) {
            return {
                active: announcement?.active,
                title: announcement?.value,
                body: announcement?.value2,
                endDate: announcement?.endDate ? new Date(announcement?.endDate) : null,
            };
        }

        return null;
    }

    async getDomain(): Promise<string> {
        const response = await this.api.get('namespaces/domain');
        return response?.domain || null;
    }

    terminate() {
        this.namespace = null;
    }
}
