import logger from 'loglevel';
import * as prefixer from 'loglevel-plugin-prefix';
import { environment } from './environments/environment';
import chalk from 'chalk';

class DuplicateFilter {
    private lastLog: string | null = null;

    filter(msg: string): boolean {
        if (msg === this.lastLog) {
            return false;
        }
        this.lastLog = msg;
        return true;
    }
}

const originalFactory = logger.methodFactory;
logger.methodFactory = function (
    methodName: logger.LogLevelNames,
    logLevel: logger.LogLevelNumbers,
    loggerName: string
): (...message: string[]) => void {
    const rawMethod = originalFactory(methodName, logLevel, loggerName);
    const filter = new DuplicateFilter();

    return function (...message) {
        if (filter.filter(message[0])) {
            rawMethod(...message);
        }
    };
};

logger.rebuild();

const LOG_COLORS = {
    TRACE: chalk.magenta,
    DEBUG: chalk.cyan,
    INFO: chalk.white,
    WARN: chalk.yellow,
    ERROR: chalk.red,
};

prefixer.reg(logger);
prefixer.apply(logger, {
    format(level, name, timestamp) {
        return `${chalk.gray(`[${timestamp}]`)} ${chalk.bold(LOG_COLORS[level](`[${name}]`))} ${LOG_COLORS[level](
            level
        )}: `;
    },
    levelFormatter(level) {
        return level.toUpperCase();
    },
    timestampFormatter(date) {
        return date.toISOString();
    },
});

logger.setLevel(environment.production ? 'INFO' : 'DEBUG');
