import { Injectable } from '@angular/core';
import { Storage, getDownloadURL, ref, uploadBytes } from '@angular/fire/storage';
import { LoadingController, ModalController } from '@ionic/angular';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Document } from '../../models/document';
import { environment } from '../../../environments/environment';
import { DocumentsRequests } from '../../util/documents-requests';
import { PdfComponent } from '../../modals/pdf-viewer/pdf-viewer.component';
import { PhotoViewerComponent } from '../../modals/photo-viewer/photo-viewer.component';
import { Capacitor } from '@capacitor/core';
import { Browser, OpenOptions } from '@capacitor/browser';
import logger from 'loglevel';

const b64Prefix = 'data:image/jpeg;base64,';
// const b64PngPrefix = 'data:image/png;base64,';
// const b64JpgPrefix = 'data:image/jpg;base64,';
// const b64PdfPrefix = 'data:application/pdf;base64,';

@Injectable({
    providedIn: 'root',
})
export class DocumentUtilService {
    imgs: string[] = [];

    constructor(
        private storage: Storage,
        private documentsRequests: DocumentsRequests,
        private modalController: ModalController,
        private loadingController: LoadingController
    ) {}

    async getImgFromDevice(sourceType: CameraSource): Promise<Document> {
        try {
            const photo: Photo = await Camera.getPhoto({
                quality: 50,
                resultType: CameraResultType.Base64,
                source: sourceType,
                correctOrientation: true,
                width: 800, // Adjust as needed
            });

            const img = `data:image/jpeg;base64,${photo.base64String}`;

            const doc: Document = {
                name: `${new Date().getTime().toString()}.jpeg`,
                file: img,
                mimetype: 'image/jpeg',
            };

            return doc;
        } catch (error) {
            logger.error('Error capturing image:', error);
            throw error;
        }
    }

    async getCroppedImg(targetWidth: number, targetHeight: number, sourceType: CameraSource): Promise<string> {
        try {
            const photo: Photo = await Camera.getPhoto({
                quality: 50,
                resultType: CameraResultType.Base64,
                source: sourceType,
                correctOrientation: true,
                width: targetWidth,
                height: targetHeight,
            });

            return `data:image/jpeg;base64,${photo.base64String}`;
        } catch (error) {
            logger.error('Error cropping image:', error);
            throw error;
        }
    }

    async documentUpload(doc: any, path: string, type: string): Promise<string> {
        try {
            const blob = typeof doc === 'string' ? await (await fetch(doc)).blob() : doc;
            const storageRef = ref(this.storage, path);
            await uploadBytes(storageRef, blob);
            const downloadURL = await getDownloadURL(storageRef);
            return downloadURL;
        } catch (error) {
            logger.error('Error uploading document:', error);
            throw error;
        }
    }

    async openDocument(document: Document, dismissLoader = false): Promise<any> {
        if (document.mimetype.startsWith('image') && Capacitor.isNativePlatform()) {
            const modal = await this.modalController.create({
                component: PhotoViewerComponent,
                componentProps: {
                    images: [document],
                    index: 0,
                    imageDeleteAllowed: false,
                },
            });
            await modal.present();
            if (dismissLoader) {
                await this.dismissLoaderFromDocumentsDetail();
            }
            return null;
        } else if (document.mimetype.startsWith('application/pdf')) {
            // loader gets dismissed in PdfComponent
            const modal = await this.modalController.create({
                component: PdfComponent,
                componentProps: {
                    document,
                },
                cssClass: 'fullscreen',
            });
            await modal.present();
            return null;
        } else if (typeof document.file === 'string' && document.file.startsWith('data:')) {
            if (dismissLoader) {
                await this.dismissLoaderFromDocumentsDetail();
            }

            const pdfWindow = window.open('');
            pdfWindow?.document.write(`<iframe width="100%" height="100%" src="${encodeURI(document.file)}"></iframe>`);

            return null;
        } else if (
            typeof document.file === 'string' &&
            document.file.startsWith('https') &&
            document.file.includes(environment.firebase.storageBucket)
        ) {
            if (dismissLoader) {
                await this.dismissLoaderFromDocumentsDetail();
            }
            window.open(document.file);
            return null;
        } else {
            if (dismissLoader) {
                await this.dismissLoaderFromDocumentsDetail();
            }
            logger.error('Unknown Document', document.file);
            return null;
        }
    }

    openInBrowser(url: string): void {
        const openOption: OpenOptions = {
            url: url,
            windowName: '_system',
        };
        Browser.open(openOption);
    }

    readFile(file: File, name: string, type: string): Promise<Document> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onloadend = () => {
                const doc: Document = {
                    name,
                    file: reader.result as string,
                    mimetype: type,
                };
                resolve(doc);
            };
            reader.onerror = () => {
                reject('Error reading file.');
            };
        });
    }

    async prepareDocuments(documents: any): Promise<{ imgs: any[]; pdfs: any[] }> {
        const imgs = [];
        const pdfs = [];

        for (const img of documents.imgs) {
            if (!img.isAlreadyUploaded) {
                imgs.push(this.downloadCheckIfFileOrReturnBlob(img));
            }
        }
        for (const pdf of documents.pdfs) {
            if (!pdf.isAlreadyUploaded) {
                pdfs.push(this.downloadCheckIfFileOrReturnBlob(pdf));
            }
        }

        return { imgs: await Promise.all(imgs), pdfs: await Promise.all(pdfs) };
    }

    async downloadCheckIfFileOrReturnBlob(file: any): Promise<any> {
        if (file && typeof file.file === 'string') {
            const blob = await (await fetch(file.file)).blob();
            return { name: file.name, file: blob };
        }
        return file;
    }

    async deleteFilesFromObject(
        objectId: string,
        objectKey: string,
        oldDocuments: any,
        newDocuments: any
    ): Promise<void> {
        if ((oldDocuments?.pdfs || oldDocuments?.imgs) && (newDocuments?.pdfs || newDocuments?.imgs)) {
            const fileToDelete = this.getDeletedFile(oldDocuments, newDocuments);

            if (fileToDelete) {
                const type = fileToDelete.mimetype.includes('pdf') ? 'pdfs' : 'imgs';
                for (let i = 0; i < oldDocuments[type].length; i++) {
                    if (oldDocuments[type][i].name === fileToDelete.name) {
                        // eslint-disable-next-line no-await-in-loop
                        const result: any = await this.documentsRequests.delete(objectKey, objectId, type, i);

                        // eslint-disable-next-line no-await-in-loop
                        await this.deleteFilesFromObject(objectId, objectKey, result.documents, newDocuments);
                    }
                }
            }
        }
    }

    private getDeletedFile(oldDocuments: any, newDocuments: any): any {
        const mergedTypesOld = [...oldDocuments.pdfs, ...oldDocuments.imgs];
        const mergedTypesNew = [...newDocuments.pdfs, ...newDocuments.imgs];

        const deletedFiles = mergedTypesOld.filter(
            (oldDoc: Document) =>
                !mergedTypesNew.find(
                    (newDoc: Document) => newDoc.name === oldDoc.name && newDoc.mimetype === oldDoc.mimetype
                )
        );
        return deletedFiles[0];
    }

    /**
     * Dismiss loader triggered from documents-detail
     *
     * @private
     */
    private async dismissLoaderFromDocumentsDetail(): Promise<void> {
        try {
            await this.loadingController.dismiss(null, null, 'document-loading');
        } catch {
            // Loader not present
        }
    }
}
