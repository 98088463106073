import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LanguageSelectionComponent } from 'src/app/modals/language-selection/language-selection.component';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user/user.service';
import { PopupService } from 'src/app/services/popup/popup.service';
import logger from 'loglevel';

@Component({
    selector: 'app-token-profile',
    templateUrl: './token-profile.page.html',
    styleUrls: ['./token-profile.page.scss'],
})
export class TokenProfilePage implements OnInit {
    public registrationLink;
    constructor(
        private translate: TranslateService,
        private modalCtrl: ModalController,
        private popupService: PopupService,
        private userService: UserService
    ) {}

    async ngOnInit() {
        try {
            this.registrationLink = await this.userService.getRegistrationLink();
        } catch (error) {
            logger.error(error);
        }
    }
    async onOpenLanguageModal() {
        const languageModal = await this.modalCtrl.create({
            component: LanguageSelectionComponent,
        });
        await languageModal.present();
        const { data } = await languageModal.onDidDismiss();
        if (data) {
            this.translate.resetLang(data);
            try {
                await this.userService.setLanguage(data);
                this.translate.use(data);
            } catch (err) {
                logger.error(`Set Language ${data} failed`, err);
                this.popupService.showToast(this.translate.instant('profile.change-language.error'));
            }
        }
    }
}
