<ion-header>
    <app-topbar [title]="'language-selection.header' | translate" [left]="'close'" (leftClicked)="close()"></app-topbar>
    <ion-searchbar
        class="swiper-no-swiping searchbar-style"
        [placeholder]="'language-selection.search' | translate"
        (ionInput)="searchLanguage($event.target.value)"></ion-searchbar>
</ion-header>

<ion-content>
    <ion-radio-group [value]="favoriteLanguage?.iso639_1" (ionChange)="setLanguage($event.detail.value)">
        <ion-item detail="false" *ngIf="favoriteLanguage" (click)="close()">
            <ion-icon slot="start" name="flag"></ion-icon>
            <ion-radio [value]="favoriteLanguage.iso639_1">{{ favoriteLanguage.nativeName }}</ion-radio>
        </ion-item>
        <ng-container *ngFor="let language of languages">
            <ion-item detail="false" *ngIf="language.iso639_1 !== favoriteLanguage?.iso639_1">
                <ion-icon slot="start" name="flag"></ion-icon>
                <ion-radio [value]="language.iso639_1"> {{ language.nativeName }}</ion-radio>
            </ion-item>
        </ng-container>
    </ion-radio-group>
</ion-content>
