import { Injectable } from '@angular/core';
import { collection, collectionData, doc, docData, Firestore, getDoc, updateDoc } from '@angular/fire/firestore';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { switchMap, skipWhile, take } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { DocumentUtilService } from '../document-util/document-util.service';
import { User } from '../../models/user';
import { Theme } from '../../models/theme';
import { NsUtil } from '../../util/ns';
import { registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { OriginService } from '../origin/origin.service';
import { Auth, authState, User as FirebaseUser } from '@angular/fire/auth';
import { PushNotificationService } from '../push-notification/push-notification.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    user: User = null;
    user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    userLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    profilePicture: string = null;
    namespaceFromUrl: string;
    private namespace: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    firebaseUser: FirebaseUser;
    moduleSubscription = new BehaviorSubject<string[]>([]);

    constructor(
        private firestore: Firestore,
        private afAuth: Auth,
        private documentUtilService: DocumentUtilService,
        private ns: NsUtil,
        private apiService: ApiService,
        private loadingController: LoadingController,
        private pushNotificationService: PushNotificationService,
        private translate: TranslateService,
        private originService: OriginService
    ) {
        authState(this.afAuth)
            .pipe(
                switchMap(async (user: FirebaseUser) => {
                    if (user) {
                        this.firebaseUser = user;
                        await this.pushNotificationService.initialize();
                        try {
                            const idTokenResult = await user.getIdTokenResult();
                            return { idTokenResult, user };
                        } catch {
                            return { idTokenResult: of(null), user };
                        }
                    } else {
                        this.firebaseUser = null;
                        return { idTokenResult: of(null), user };
                    }
                }),
                switchMap((result: any) => {
                    if (result?.user) {
                        this.moduleSubscription.next(result.idTokenResult?.claims?.modules || []);
                        return docData(doc(this.firestore, `users/${result.user.uid}`));
                    } else {
                        return of(null);
                    }
                })
            )
            .subscribe((user?: User) => {
                if (user) {
                    this.user = user;
                    this.namespace.next(this.user.ns);
                    this.ns.setNs(user.ns);
                    this.user$.next(user);
                } else {
                    this.user = null;
                    this.namespace.next(null);
                    this.ns.setNs(null);
                    this.user$.next(null);
                }
            });
    }

    async getUser(): Promise<User> {
        if (this.user) {
            return Promise.resolve(this.user);
        }
        return firstValueFrom(
            this.user$.pipe(
                skipWhile((user) => user == null),
                take(1)
            )
        );
    }

    async getTenantsByIds(ids: string[]) {
        return (await this.apiService.get(`users?id=${ids.join(',')}`)) as any[];
    }

    async getToken(): Promise<string> {
        if (this.firebaseUser) {
            return this.firebaseUser.getIdToken();
        }
        return null;
    }

    getNamespace(): string {
        return this.namespace.getValue() || this.namespaceFromUrl;
    }

    getNamespaceFromUrl(): string {
        return this.namespaceFromUrl;
    }

    setNameSpaceFromUrl(ns: string) {
        this.namespaceFromUrl = ns;
    }

    getNamespaceObservable(): Observable<string> {
        return this.namespace.asObservable();
    }

    updateUser(data): Promise<any> {
        data.id = this.user.id;
        return this.apiService.put(`users`, data);
    }

    createUserContact(data): Promise<any> {
        return this.apiService.post(`users/${this.user.id}/contacts`, {
            ...data,
            userId: this.user.id,
        });
    }

    updateUserContact(data): Promise<any> {
        return this.apiService.put(`users/${this.user.id}/contacts`, {
            ...data,
            userId: this.user.id,
        });
    }

    deleteUserContact(id: string): Promise<any> {
        return this.apiService.delete(`users/${this.user.id}/contacts/${id}`);
    }

    getUserContactsAsObservable(): Observable<any> {
        return collectionData(collection(this.firestore, `users/${this.user.id}/contacts`));
    }

    async requestEmailChange(newEmail: string): Promise<any> {
        return await this.apiService.post(`users/requestEmailChange`, {
            newEmail,
        });
    }

    /**
     * returns push and email notification settings of user
     */
    public getNotificationSetting(): { push: boolean; email: boolean } {
        const push = this.user.pushNotifications;
        const email = this.user.emailNotifications;

        return {
            push: !push ? true : !!push.length,
            email: !email ? true : !!email.length,
        };
    }

    /**
     * sets push notification settings
     * @param value new value of push notification settings
     */
    public async setBlockContact(value: boolean): Promise<any> {
        return await this.apiService.put('users', {
            id: this.user.id,
            blockContact: Boolean(value),
        });
    }

    /**
     * sets push notification settings
     * @param value new value of push notification settings
     */
    public async setPushNotifications(value: boolean): Promise<any> {
        return await this.apiService.put('users', {
            id: this.user.id,
            pushNotifications: value ? ['all'] : [],
        });
    }

    /**
     * sets email notification settings
     * @param value new value of email notification settings
     */
    public async setEmailNotifications(value: boolean): Promise<any> {
        return await this.apiService.put('users', {
            id: this.user.id,
            emailNotifications: value ? ['all'] : [],
        });
    }

    /**
     *
     * @param lang Sprachkürzel nach Google Translate API z.B. 'de', 'fr', 'es', 'en' etc.
     * siehe: https://cloud.google.com/translate/docs/languages
     * @param noSave
     */
    async setLanguage(lang: string, noSave?: any): Promise<any> {
        await this.localeInitializer(lang);
        if (noSave) {
            this.userLanguage$.next(lang);
        }
        if (!this.user) {
            return Promise.reject();
        } else if (lang === this.user.language) {
            return Promise.resolve();
        }
        if (!noSave) {
            const loader = await this.loadingController.create();
            await loader.present();
            await this.apiService.put(`users/${this.user.id}/language/${lang}`);
            this.userLanguage$.next(lang);
            await loader.dismiss();
        }
    }

    async localeInitializer(localeId: string): Promise<void> {
        try {
            let localeModule: any;

            switch (localeId) {
                case 'hy':
                    localeModule = await import('@angular/common/locales/hy');
                    break;
                case 'pa':
                    localeModule = await import('@angular/common/locales/pa');
                    break;
                case 'no':
                    localeModule = await import('@angular/common/locales/no');
                    break;
                case 'ms':
                    localeModule = await import('@angular/common/locales/ms');
                    break;
                case 'bg':
                    localeModule = await import('@angular/common/locales/bg');
                    break;
                case 'sw':
                    localeModule = await import('@angular/common/locales/sw');
                    break;
                case 'el':
                    localeModule = await import('@angular/common/locales/el');
                    break;
                case 'ka':
                    localeModule = await import('@angular/common/locales/ka');
                    break;
                case 'hu':
                    localeModule = await import('@angular/common/locales/hu');
                    break;
                case 'ku':
                    localeModule = await import('@angular/common/locales/ku');
                    break;
                case 'ky':
                    localeModule = await import('@angular/common/locales/ky');
                    break;
                case 'lo':
                    localeModule = await import('@angular/common/locales/lo');
                    break;
                case 'lb':
                    localeModule = await import('@angular/common/locales/lb');
                    break;
                case 'si':
                    localeModule = await import('@angular/common/locales/si');
                    break;
                case 'vi':
                    localeModule = await import('@angular/common/locales/vi');
                    break;
                case 'uz':
                    localeModule = await import('@angular/common/locales/uz');
                    break;
                case 'en':
                    localeModule = await import('@angular/common/locales/en');
                    break;
                case 'es':
                    localeModule = await import('@angular/common/locales/es');
                    break;
                case 'cs':
                    localeModule = await import('@angular/common/locales/cs');
                    break;
                case 'da':
                    localeModule = await import('@angular/common/locales/da');
                    break;
                case 'ja':
                    localeModule = await import('@angular/common/locales/ja');
                    break;
                case 'mn':
                    localeModule = await import('@angular/common/locales/mn');
                    break;
                case 'my':
                    localeModule = await import('@angular/common/locales/my');
                    break;
                case 'pl':
                    localeModule = await import('@angular/common/locales/pl');
                    break;
                case 'ta':
                    localeModule = await import('@angular/common/locales/ta');
                    break;
                case 'so':
                    localeModule = await import('@angular/common/locales/so');
                    break;
                case 'xh':
                    localeModule = await import('@angular/common/locales/xh');
                    break;
                case 'sv':
                    localeModule = await import('@angular/common/locales/sv');
                    break;
                case 'ca':
                    localeModule = await import('@angular/common/locales/ca');
                    break;
                case 'pt':
                    localeModule = await import('@angular/common/locales/pt');
                    break;
                case 'nl':
                    localeModule = await import('@angular/common/locales/nl');
                    break;
                case 'de':
                    localeModule = await import('@angular/common/locales/de');
                    break;
                case 'az':
                    localeModule = await import('@angular/common/locales/az');
                    break;
                case 'fr':
                    localeModule = await import('@angular/common/locales/fr');
                    break;
                case 'sr':
                    localeModule = await import('@angular/common/locales/sr');
                    break;
                case 'am':
                    localeModule = await import('@angular/common/locales/am');
                    break;
                case 'ur':
                    localeModule = await import('@angular/common/locales/ur');
                    break;
                case 'it':
                    localeModule = await import('@angular/common/locales/it');
                    break;
                case 'id':
                    localeModule = await import('@angular/common/locales/id');
                    break;
                case 'ga':
                    localeModule = await import('@angular/common/locales/ga');
                    break;
                case 'lt':
                    localeModule = await import('@angular/common/locales/lt');
                    break;
                case 'af':
                    localeModule = await import('@angular/common/locales/af');
                    break;
                case 'ne':
                    localeModule = await import('@angular/common/locales/ne');
                    break;
                case 'tg':
                    localeModule = await import('@angular/common/locales/tg');
                    break;
                case 'ps':
                    localeModule = await import('@angular/common/locales/ps');
                    break;
                case 'ru':
                    localeModule = await import('@angular/common/locales/ru');
                    break;
                case 'bn':
                    localeModule = await import('@angular/common/locales/bn');
                    break;
                case 'bs':
                    localeModule = await import('@angular/common/locales/bs');
                    break;
                case 'km':
                    localeModule = await import('@angular/common/locales/km');
                    break;
                case 'tr':
                    localeModule = await import('@angular/common/locales/tr');
                    break;
                case 'et':
                    localeModule = await import('@angular/common/locales/et');
                    break;
                case 'fi':
                    localeModule = await import('@angular/common/locales/fi');
                    break;
                case 'is':
                    localeModule = await import('@angular/common/locales/is');
                    break;
                case 'lv':
                    localeModule = await import('@angular/common/locales/lv');
                    break;
                case 'mk':
                    localeModule = await import('@angular/common/locales/mk');
                    break;
                case 'mg':
                    localeModule = await import('@angular/common/locales/mg');
                    break;
                case 'mi':
                    localeModule = await import('@angular/common/locales/mi');
                    break;
                case 'ko':
                    localeModule = await import('@angular/common/locales/ko');
                    break;
                case 'zu':
                    localeModule = await import('@angular/common/locales/zu');
                    break;
                case 'sq':
                    localeModule = await import('@angular/common/locales/sq');
                    break;
                case 'ar':
                    localeModule = await import('@angular/common/locales/ar');
                    break;
                case 'be':
                    localeModule = await import('@angular/common/locales/be');
                    break;
                case 'hr':
                    localeModule = await import('@angular/common/locales/hr');
                    break;
                case 'sk':
                    localeModule = await import('@angular/common/locales/sk');
                    break;
                case 'hi':
                    localeModule = await import('@angular/common/locales/hi');
                    break;
                case 'fa':
                    localeModule = await import('@angular/common/locales/fa');
                    break;
                case 'he':
                    localeModule = await import('@angular/common/locales/he');
                    break;
                case 'kk':
                    localeModule = await import('@angular/common/locales/kk');
                    break;
                case 'mt':
                    localeModule = await import('@angular/common/locales/mt');
                    break;
                case 'ro':
                    localeModule = await import('@angular/common/locales/ro');
                    break;
                case 'sl':
                    localeModule = await import('@angular/common/locales/sl');
                    break;
                case 'th':
                    localeModule = await import('@angular/common/locales/th');
                    break;
                case 'uk':
                    localeModule = await import('@angular/common/locales/uk');
                    break;
                case 'sn':
                    localeModule = await import('@angular/common/locales/sn');
                    break;
                case 'cy':
                    localeModule = await import('@angular/common/locales/cy');
                    break;
                case 'eo':
                    localeModule = await import('@angular/common/locales/eo');
                    break;
                case 'eu':
                    localeModule = await import('@angular/common/locales/eu');
                    break;
                case 'fy':
                    localeModule = await import('@angular/common/locales/fy');
                    break;
                case 'gd':
                    localeModule = await import('@angular/common/locales/gd');
                    break;
                case 'gl':
                    localeModule = await import('@angular/common/locales/gl');
                    break;
                case 'gu':
                    localeModule = await import('@angular/common/locales/gu');
                    break;
                case 'ha':
                    localeModule = await import('@angular/common/locales/ha');
                    break;
                case 'haw':
                    localeModule = await import('@angular/common/locales/haw');
                    break;
                case 'ig':
                    localeModule = await import('@angular/common/locales/ig');
                    break;
                case 'kn':
                    localeModule = await import('@angular/common/locales/kn');
                    break;
                case 'ml':
                    localeModule = await import('@angular/common/locales/ml');
                    break;
                case 'mr':
                    localeModule = await import('@angular/common/locales/mr');
                    break;
                case 'sd':
                    localeModule = await import('@angular/common/locales/sd');
                    break;
                case 'su':
                    localeModule = await import('@angular/common/locales/su');
                    break;
                case 'yi':
                    localeModule = await import('@angular/common/locales/yi');
                    break;
                case 'yo':
                    localeModule = await import('@angular/common/locales/yo');
                    break;
                case 'zh-TW':
                    localeModule = await import('@angular/common/locales/zh');
                    break;
                default:
                    localeModule = await import('@angular/common/locales/en');
                    console.warn(`Locale data for '${localeId}' not found, using 'en' as fallback.`);
            }

            registerLocaleData(localeModule.default);
        } catch (error) {
            console.error(`Error loading locale data for '${localeId}':`, error);
        }
    }

    async updateProfilePicture(img: any) {
        const downloadUrl = await this.documentUtilService.documentUpload(
            img,
            `${this.user.id}/profilePicture.jpeg`,
            'img'
        );
        const userDocRef = doc(this.firestore, `users/${this.user.id}`);
        return updateDoc(userDocRef, { profilePicture: downloadUrl });
    }

    deleteProfilePicture() {
        const userDocRef = doc(this.firestore, `users/${this.user.id}`);
        return updateDoc(userDocRef, { profilePicture: null });
    }

    async getTheme(): Promise<Theme> {
        const docRef = doc(this.firestore, `ns/${this.getNamespace()}`);
        const nsSnap = await getDoc(docRef);
        return nsSnap.data()?.theme as Theme;
    }

    getUserFullName(user?: any) {
        const userObj = user || this.user;

        return userObj.firstname ? `${userObj.firstname} ${userObj.lastname}` : userObj.lastname;
    }

    async getRegistrationLink() {
        const link = await this.apiService.get(`users/${this.user.ns}/${this.user.id}/getRegistrationLink`);
        return link.registrationLink;
    }

    terminate() {
        this.userLanguage$.next(null);
    }

    getTenantFullName(tenant: User, nameSeperator = ' ') {
        return tenant.firstname ? `${tenant.firstname}${nameSeperator}${tenant.lastname}` : tenant.lastname;
    }
    async getTenant(id: string): Promise<any> {
        return this.apiService.get(`users/${id}`);
    }

    async getTenants(ids: string[]): Promise<any> {
        return await Promise.all(ids.map((id) => this.getTenant(id)));
    }

    async getTenantsByPropertyId(propertyId: string): Promise<any> {
        return this.apiService.get(`users/usersByProperty/${propertyId}`);
    }

    async getTenantsByFlatId(flatId: string): Promise<any> {
        return this.apiService.get(`users/usersByFlat/${flatId}`);
    }

    async getBadges(): Promise<{
        appStore: { url: string; img: string };
        playStore: { url: string; img: string };
    }> {
        const origin = this.originService.origin;
        const [appStore, playStore] = await Promise.all([
            this.apiService.get(`users/${origin}/appstore`),
            this.apiService.get(`users/${origin}/playstore`),
        ]);

        appStore.img = 'https://storage.googleapis.com/public_woonig_assets/images/app_store_badge.png';
        playStore.img = `https://play.google.com/intl/en/badges/images/generic/${this.translate.getBrowserLang()}_badge_web_generic.png`;
        return { appStore, playStore };
    }

    async uploadDocumentsToPrivateCloud(files: any[], label?: string) {
        const formData = new FormData();
        for (const file of files) {
            const name = file.editedName && file.editedName.length ? file.editedName : file.name;

            const fileData = file.original
                ? file.original
                : typeof file.file === 'string'
                  ? // eslint-disable-next-line no-await-in-loop
                    await (await fetch(file.file)).blob()
                  : file.file;
            formData.append(name, fileData, name);
        }

        const queryParam = label ? `?label=${encodeURIComponent(label)}` : '';

        return await this.apiService.post(`users/${this.user.id}/privateDocuments${queryParam}`, formData);
    }

    async renamePrivateDocument(docType: 'imgs' | 'pdfs', index: number, docName: string) {
        return this.apiService.post(`users/${this.user.id}/privateDocuments/${docType}/${index}/rename`, {
            name: docName,
        });
    }

    async sharePrivateDocumentWithUser(docType: string, index: number) {
        return await this.apiService.post(`users/${this.user.id}/sharePrivateDocuments/${docType}/${index}`);
    }

    async deleteDocumentFromPrivateCloud(docType: string, index: number) {
        return await this.apiService.delete(`users/${this.user.id}/privateDocuments/${docType}/${index}`);
    }

    async updateLabels(labels: string[]) {
        await this.apiService.post(`users/${this.user.id}/privateDocuments/labels`, {
            labels: labels,
        });
    }

    async renameLabel(label: string, name: string) {
        await this.apiService.post(`users/${this.user.id}/privateDocuments/labels/${encodeURIComponent(label)}`, {
            name,
        });
    }

    async deleteLabel(label: string) {
        await this.apiService.delete(`users/${this.user.id}/privateDocuments/labels/${encodeURIComponent(label)}`);
    }

    async updateDocumentLabels(type: string, index: number, labels: string[]) {
        await this.apiService.post(`users/${this.user.id}/privateDocuments/${type}/${index}/labels`, {
            labels,
        });
    }

    getPrivateCloudObservable(id: string): Observable<any> {
        const docRef = doc(this.firestore, `userPrivateCloud/${id}`);
        return docData(docRef);
    }
}
