<ion-header>
    <app-topbar
        [title]="'appointments.header' | translate"
        [left]="'close'"
        (leftClicked)="modalController.dismiss()"></app-topbar>
</ion-header>
<ion-content>
    <ion-card>
        <ion-card-content>
            <div class="card-section">
                <h3 class="section-title">
                    {{ 'appointments.days' | translate }}
                </h3>
                <div class="ion-padding-bottom">
                    <app-list-with-chip
                        (itemSelected)="daySelected($event)"
                        (chipClicked)="chipClicked($event)"
                        [items]="days"></app-list-with-chip>
                </div>
            </div>
            <div class="card-section ion-padding-bottom">
                <h3 class="section-title">
                    {{ 'appointments.times' | translate }}
                </h3>
                <div class="ion-padding-bottom">
                    <app-list-with-chip (itemSelected)="timeSelected($event)" [items]="times"></app-list-with-chip>
                </div>
            </div>
        </ion-card-content>
    </ion-card>
</ion-content>
<ion-footer>
    <ion-toolbar class="entity-selection-footer">
        <ion-button
            class="button-with-radius entity-selection-button"
            expand="block"
            color="primary"
            [disabled]="!setAppointments.length"
            (click)="submitAppointments()">
            {{ 'appointments.button-submit' | translate }}
        </ion-button>
    </ion-toolbar>
</ion-footer>
