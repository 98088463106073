<ion-content class="custom-modal">
    <div class="modal-wrapper">
        <div class="modal-header">
            <h2>{{ title }}</h2>
        </div>

        <div class="modal-body">
            <p [innerHTML]="safeTranslatedMessage"></p>
        </div>

        <div class="modal-footer">
            <div *ngIf="info">
                <ion-button expand="block" color="primary" (click)="confirm(true)">{{
                    'general.okay' | translate
                }}</ion-button>
            </div>
            <span *ngIf="!info">{{ 'ota-update.restart-app' | translate }}</span>
            <div *ngIf="!info" class="button-group">
                <ion-button expand="block" color="primary" (click)="confirm(true)">{{
                    'general.yes' | translate
                }}</ion-button>
                <ion-button expand="block" color="medium" (click)="confirm(false)">{{
                    'general.no' | translate
                }}</ion-button>
            </div>
        </div>
    </div>
</ion-content>
